<template>
  <v-sheet
    class="xflex xw-full md:xflex-row xflex-col xgap-[3px] xjustify-between xitems-center"
  >
    <div
      :class="[mdUp ? 'xflex-row' : 'xflex-col']"
      class="controller xflex md:xgap-x-[10px] xgap-x-[5px] xjustify-start xitems-center xw-full"
    >
      <KirbyRangePicker
        :value="selectedValue"
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-if="type === 'custom'"
      ></KirbyRangePicker>

      <KirbyWeeklyPicker
        :value="selectedValue"
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-else-if="type === 'weekly'"
      ></KirbyWeeklyPicker>

      <KirbyMonthPicker
        :value="selectedValue"
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-else-if="type === 'monthly'"
      ></KirbyMonthPicker>
      
      <div
        class="xflex flex-row xgap-[5px] md:xmy-0 xmy-[10px] typer"
        v-if="laptopUp"
      >
        <btn-tooltip
          tip="Filter by weekly"
          :color="type === 'weekly' ? '#7A38A3' : 'base_100'"
          :disabled="fetchingCsv || type === 'weekly'"
          :loading="fetchingCsv"
          :outlined="type !== 'weekly'"
          @click="setFilter('weekly')"
          class="text-none"
        >
          Week
        </btn-tooltip>
        <btn-tooltip
          tip="Filter by monthly"
          :color="type === 'monthly' ? '#7A38A3' : 'base_100'"
          :disabled="fetchingCsv || type === 'monthly'"
          :loading="fetchingCsv"
          :outlined="type !== 'monthly'"
          @click="setFilter('monthly')"
          class="text-none"
        >
          Month
        </btn-tooltip>
        <btn-tooltip
          tip="Filter by custom date range"
          :color="type === 'custom' ? '#7A38A3' : 'base_100'"
          :disabled="fetchingCsv || type === 'custom'"
          :loading="fetchingCsv"
          :outlined="type !== 'custom'"
          @click="setFilter('custom')"
          class="text-none"
        >
          Custom
        </btn-tooltip>
      </div>
      <div
        class="xflex xflex-row xjustify-end xitems-center xgap-[5px] xw-full !xmy-[5px]"
        v-else
      >
        <btn-range-filter
          @monthly="setFilter('monthly')"
          @weekly="setFilter('weekly')"
          no-daily
          @custom="setFilter('custom')"
          :type="type"
        >
        </btn-range-filter>
        <v-spacer></v-spacer>
        <btn-tooltip
          tip="Add new PTO"
          color="primary"
          class="text-none"
          @click="add_pto_modal = true"
        >
          <v-icon left>mdi-plus</v-icon> Add PTO
        </btn-tooltip>
      </div>
    </div>
    <div
      v-if="laptopUp"
      class="xflex xgap-[0.5em] xflex-row xjustify-end xitems-center"
    >
      <btn-tooltip
        tip="Add new PTO"
        color="primary"
        class="text-none"
        @click="add_pto_modal = true"
      >
        <v-icon left>mdi-plus</v-icon> Add PTO
      </btn-tooltip>
      <slot name="extra-buttons"></slot>
    </div>

    <AddPTOModal v-model="add_pto_modal" :user="null"></AddPTOModal>
  </v-sheet>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import KirbyWeeklyPicker from "@/views/GlobalComponents/Forms/KirbyWeeklyPicker.vue";
import KirbyMonthPicker from "@/views/GlobalComponents/Forms/KirbyMonthPicker.vue";
import KirbyRangePicker from "@/views/GlobalComponents/Forms/KirbyRangePicker.vue";
import AddPTOModal from "@/views/GlobalComponents/Modals/AddPTOModal.vue";

export default {
  props: {
    type: { type: String, default: "monthly" },
    value: { type: [Object, Array, String], default: undefined },
    hasDownload: { type: Boolean, default: true },
  },
  data() {
    return {
      add_pto_modal: false,
      fetchingCsv: false,
      selectedValue: null,
      project: null,
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.selectedValue = val;
      },
      immediate: true,
      deep: true,
    },
    selectedValue: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    selectedType() {
      return this.$route.name.includes("app-pto-all") ? "all" : "pending";
    },
    csvName() {
      if (this.type === "monthly")
        return `${this.user.team.name}-Monthly-${this.value}`;
      else if (this.type === "weekly")
        return `${this.user.team.name}-Weekly-${this.value[0]}-${this.value[1]}`;
      else if (this.type === "daily")
        return `${this.user.team.name}-Daily-${this.value}`;
      else
        return `${this.user.team.name}-Custom-${this.value[0]}-${this.value[1]}`;
    },
    csvUrl() {
      if (this.type === "monthly")
        return `api/team-records/monthly/${this.value}/null/csv`;
      else if (this.type === "weekly")
        return `api/team-records/weekly/${this.value[0]}/${this.value[1]}/csv`;
      else if (this.type === "daily")
        return `api/team-records/daily/${this.value}/null/csv`;
      else
        return `api/team-records/custom/${this.value[0]}/${this.value[1]}/csv`;
    },
  },
  methods: {
    emitChange(val) {
      this.$emit("change", val);
    },
    setFilter(type) {
      if (type === "monthly")
        this.$router.push({
          name: `app-pto-${this.selectedType}-monthly`,
          params: { year_month: moment().format("YYYY-MM") },
        });
      else if (type === "weekly")
        this.$router.push({
          name: `app-pto-${this.selectedType}-weekly`,
          params: {
            start: moment().startOf("week").format("YYYY-MM-DD"),
            end: moment().endOf("week").format("YYYY-MM-DD"),
          },
        });
      else if (type === "custom")
        this.$router.push({
          name: `app-pto-${this.selectedType}-custom`,
          params: {
            start: moment().subtract(5, "day").format("YYYY-MM-DD"),
            end: moment().format("YYYY-MM-DD"),
          },
        });
    },
  },
  components: {
    KirbyWeeklyPicker,
    KirbyMonthPicker,
    KirbyRangePicker,
    AddPTOModal,
  },
};
</script>

<style lang="css">
.typer .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background: #7a38a3 !important;
  color: #fff !important;
}
</style>
